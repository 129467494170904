.projects {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    box-sizing: border-box;
    transition: all 1s ease-in-out;
    padding: 2em;
    z-index: 1;
}

.projects-btn {
    bottom: calc(16px + 1vw);
    right: calc(16px + 1vw)
}


.projects-btn:hover {
    opacity: 0.5;
    transform: scale(1.1);
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .5s, opacity .5s linear;
}

.project-list {
    display: flex;
    flex-direction: column;
    width: 40em;
    align-items: left;
    transform: perspective(1000px) rotate3d(0, 1, 0, -10deg);
}

.project-item {
    font-size: 1.8em;
    transition: all 0.2s ease-in-out;
    z-index: 10;
    white-space: nowrap;
    overflow: visible;
    padding: .2em;
}


@keyframes rotate {
    0% {
        transform: perspective(1000px) rotate3d(1, 0, 0, -10deg);
    }

    100% {
        transform: perspective(1000px) rotate3d(1, 0, 0, 10deg);
    }
}

.project-image {
    display: block;
    width: 40vw;
    /* animation: rotate 10s ease-in-out infinite alternate; */
    opacity: .9;
    transition-duration: .3s;
}

.project-image:hover {
    opacity: .5;
}

.selected {
    font-size: 2.5em;
    text-decoration: underline;
}

@media screen and (max-width: 768px),
(orientation: portrait) {
    .projects {
        flex-direction: column-reverse;
        justify-content: flex-end;
        gap: 5em;
    }

    .project-list {
        width: 100%;
        align-items: center;
        transform: perspective(none) rotate3d(none);
    }

    .project-item {
        font-size: 1.2em;
    }

    .project-image {
        width: calc(100vw - 2em);
    }
}