.contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2em;
    box-sizing: border-box;
    transition: all 1s ease-in-out;
    padding: 2em;
    z-index: 1;
}

.contact-btn {
    top: calc(16px + 1vw);
    left: calc(16px + 1vw)
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .5s, opacity .5s linear;
}

.contact-icon {
    width: 5em;
    transition: all 0.5s ease-in-out;
}

@keyframes swing {
    0% {
        transform: rotate(0deg);
    }

    33% {
        transform: rotate(30deg);
    }

    66% {
        transform: rotate(-30deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.contact-icon:hover {
    animation: swing 1s ease-in-out infinite forwards;
}

@media screen and (max-width: 768px) {
    .contact {
        gap: 1em;
    }

    .contact-icon {
        width: 3em;
    }
}