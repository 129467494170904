@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');

body {
	margin: 0;
	font-family: 'DM Mono', -apple-system, BlinkMacSystemFont, 'Roboto Mono', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
	cursor: url(cursor.svg), auto;
	overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
	letter-spacing: 0.1em;
}