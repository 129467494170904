.bg {
    font-size: 3rem;
    transition: all 0.5s ease-in-out;
    z-index: 0;
}

.closed {
    transform: translate(-50%, -50%) translateX(0vw) rotate(0deg) scale(1)
}

.right-open {
    transform: translate(-50%, -50%) translateX(-40vw) rotate(-45deg) scale(1.5)
}

.left-open {
    transform: translate(-50%, -50%) translateX(40vw) rotate(-45deg) scale(1.5)
}

.wave {
    /* fucking lag */
    /* overflow: visible; */
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0px;
    top: 0px;
    /* animation: swell 3s ease-in-out infinite; */
}

.wave>svg {
    /* fucking lag */
    /* overflow: visible; */
    position: absolute;
    right: 0px;
    top: 0px;
    /* background-color: #2e3138; */
}

path {
    fill: var(--wave-color);
}

.right-fill {
    position: absolute;
    left: 120vh;
    height: 200vh;
    width: 200vw;
    background-color: var(--wave-color);
    background: none;
}

.bottom-fill {
    position: absolute;
    top: 120vh;
    width: 200vw;
    height: 200vh;
    background-color: var(--wave-color);
}

@media all and (orientation:portrait) {
    .bg {
        height: 150vw;
        width: 150vw;
    }

    .right-fill {
        position: absolute;
        left: 150vw;
        height: 200vh;
        width: 200vw;
        background-color: var(--wave-color);
    }

    .bottom-fill {
        position: absolute;
        top: 150vw;
        width: 200vw;
        height: 200vh;
        background-color: var(--wave-color);
    }
}

@media all and (orientation:landscape) {
    .bg {
        height: 120vh;
        width: 120vh;
    }

    .right-fill {
        position: absolute;
        left: 120vh;
        height: 200vh;
        width: 200vw;
        background-color: var(--wave-color);
    }

    .bottom-fill {
        position: absolute;
        top: 120vh;
        width: 200vw;
        height: 200vh;
        background-color: var(--wave-color);
    }
}

@keyframes swell {

    0%,
    100% {
        transform: translate(-2%, 2%);
    }

    25% {
        transform: translate(2%, 2%);
    }

    50% {
        transform: translate(2%, -2%);
    }

    75% {
        transform: translate(-2%, -2%);
    }
}