.about-container {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.about {
    margin: 1em;
    transition: all 0.5s ease-in-out;
    text-align: center;
}

.about>* {
    padding: 0em;
}

.description {
    margin: 0 0 1em;
    color: #c0c0c5;
}

h1 {
    margin: 0 0 0.5em;
}

h3 {
    margin: 0 0 2.5em;
}

@keyframes moveup {
    0% {
        transform: translateY(4em);
    }

    100% {
        transform: translateY(0)
            /*rotate3d(1, 0, 0, 20deg) */
        ;
    }
}

.move {
    animation: 0.5s ease-in-out 0.5s moveup both;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade {
    margin: 0;
    padding: 0;
    animation: 1s linear 0.7s fadein backwards;
}