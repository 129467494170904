:root {
    --background-color: hsl(222 11% 14%);
    --wave-color: hsl(222 10% 18%);
}

.App {
    background-color: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: white;
    height: 100vh;
    overflow: hidden;
    user-select: none;
    z-index: -1;
}

.maximized {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.center {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}

.hidden {
    display: none;
}

.btn {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    transition: all 0.2s ease-in-out;
    z-index: 10;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .btn {
        font-size: 1.2rem;
    }
}

.btn:hover {
    opacity: 0.5;
    transform: scale(1.1);
}